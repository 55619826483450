import {getRequest, postRequest} from "./AxiosClient";
import {useQuery} from "@tanstack/react-query";
import {CheckInModel} from "../models/CheckInModel";
import {ImageType} from "react-images-uploading";
import axios from "axios";
import {CheckInPhotoPresigned, CheckInPhotoPresigedResponse} from "../models/CheckInPhotoPresigned";
import Cookies from "universal-cookie";

const CHECK_IN_ID = "994781fc-df06-44bc-a4ab-e6e86763556a";

export async function getCheckIn(check_in_id: string) {
    try {
        const res = await getRequest("v1/api/check_in/" + check_in_id, {}, "CHECK_IN");
        return res.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not fetch check in"));
    }
}

export const useGetCheckIn = (check_in_id: string) => {
    return useQuery<CheckInModel>(["get_check_in", CHECK_IN_ID], () => getCheckIn(check_in_id), {enabled: !!CHECK_IN_ID});
};

// @ts-ignore
export async function getPresignedUploadUrl(imageName: string | undefined): CheckInPhotoPresigned {
    if (imageName === undefined) {
        return Promise.reject(new Error("Image Name not set"));
    }
    try {
        // @ts-ignore
        const {data: photoResponse} = await postRequest<CheckInPhotoPresigedResponse>(
            "v1/api/check_in/photo/presigned_url",
            {
                "filename": imageName
            },
            {},
            "CHECK_IN"
        );
        return photoResponse;
    } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not retrieve url"));
    }
}


export async function uploadFileToS3(image: ImageType, url: string) {
    try {
        const axiosClient = axios.create({
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        const res = await axiosClient.put(url, image.file);
        return res;
    } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not Upload Photo"));
    }
}


export async function submitCheckIn(bodyRequest = {}) {
    try {
        const response = await postRequest("v1/api/check_in/client", bodyRequest, {}, "CHECK_IN");
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not Submit Check In"));
    }
}

export async function loginClient(email: string) {
    try {

        const requestBody = {
            "email": email
        };

        const response = await postRequest("v1/api/client/login", requestBody, {});
        const data = response.data;
        const cookies = new Cookies();
        cookies.set("ampt-auth", data["token"]);
        // console.log(cookies.get("ampt-auth"));
        return data["token"];
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function loadClientCheckIn(userId: string) {
    try {
        const response = await getRequest(`v1/api/check_in/client/${userId}/form`, {}, "CHECK_IN");
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
