import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import "./CheckInForm.css";
import {
    getPresignedUploadUrl, loadClientCheckIn,
    loginClient,
    submitCheckIn,
    uploadFileToS3,
} from "../services/CheckInApiService";
import {FormControl, InputLabel, Select, SelectChangeEvent, Slider} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {CheckInQuestionModel} from "../models/CheckInQuestionModel";
import ImageUploading, {ImageListType, ImageType} from "react-images-uploading";
import jwt_decode from "jwt-decode";
import {CheckInModel} from "../models/CheckInModel";
import {CheckInSubmitted} from "./CheckInSubmitted";
import {UserNotfound} from "./UserNotFount";
import {ErrorLoadingCheckForm} from "./ErrorLoadingCheckForm";


function CheckInForm() {

    // 1 - Initial Load -- Email Input
    // 2 - Check In Not Found for Email
    // 3 - Check In Loaded
    // 4 - Check in Submitted
    const [operatingMode, setOperatingMode] = React.useState(1);
    const [checkIn, setCheckIn] = React.useState<CheckInModel>();
    const [userEmail, setUserEmail] = React.useState("");
    const [userName, setUserName] = React.useState("");
    const [images, setImages] = React.useState([]);
    const [imagesUploaded, setImagesUploaded] = React.useState([]);
    const [imagesSaved, setImgesSaved] = React.useState([]);
    const [canSubmit, setCanSubmit] = React.useState(true);
    const maxNumber = 5;


    const findImage = (image: ImageType) => {
        // eslint-disable-next-line array-callback-return
        imagesUploaded.map((uploadedImage) => {
            if (uploadedImage === image) {
                return true;
            }
        });
        return false;
    };

    const uploadImage = async (image: ImageType) => {
        const photoResponse = await getPresignedUploadUrl(image.file?.name);
        const response = await uploadFileToS3(image, photoResponse.url);

        if (response.status === 200) {
            // @ts-ignore
            setImagesUploaded([...imagesUploaded, image]);
            // @ts-ignore
            setImgesSaved([...imagesSaved, {"id": photoResponse.record.id, "name": image.file?.name}]);
        }
    };

    const onChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        setCanSubmit(false);
        // eslint-disable-next-line array-callback-return
        imageList.map((image) => {
            let imageUploadStatus = findImage(image);
            if (!imageUploadStatus) {
                // Upload The Image
                uploadImage(image);
            }
        });
        // data for submit
        setImages(imageList as never[]);
        setCanSubmit(true);
    };

    const handleSubmit = async () => {
        if (canSubmit) {
            setCanSubmit(false);
            const requestPayload = {
                "questions": checkIn?.questions,
                "images": imagesSaved
            };
            await submitCheckIn(requestPayload);
            setCanSubmit(true);
            setOperatingMode(4);
        }
    };


    const handleChange = (event: SelectChangeEvent, question: CheckInQuestionModel) => {
        question.selectedValue = event.target.value;
    };

    const handleFormStart = async () => {
        setOperatingMode(1);
        const token = await loginClient(userEmail);

        if (!token) {
            setOperatingMode(2);
            return;
        }
        const decodedToken = jwt_decode(token);
        // @ts-ignore
        const tokenEmail = decodedToken["email"];
        // @ts-ignore
        const tokenName = decodedToken["name"];
        // @ts-ignore
        const tokenUserId = decodedToken["user_id"];

        const loadedCheckIn = await loadClientCheckIn(tokenUserId);
        if (loadedCheckIn["error"] !== undefined) {
            setOperatingMode(5);
            return null;
        }
        // @ts-ignore
        setCheckIn(loadedCheckIn);
        setUserName(tokenName);
        setUserEmail(tokenEmail);
        setOperatingMode(3);
    };

    const InitialLoad = () => {
        return <div className="checkInQuestion">
            <h5>Email Address</h5>
            <TextField id="emailInput" onChange={function (e) {
                e.preventDefault();
                setUserEmail(e.target.value);
            }} onKeyDown={function (e) {
                if (e.key === "Enter") {
                    e.preventDefault();
                    handleFormStart();
                }
            }}
                       onSubmit={function (e) {
                           e.preventDefault();
                       }}/>
            <Button variant="contained" onClick={handleFormStart}>Start</Button>
        </div>;
    };


    const LoadUserForm = () => {
        let response = [];

        const userFormHeader = <h3>Hey {userName}! Let's do your weekly check in!</h3>;
        response.push(userFormHeader);

        const questions = (checkIn?.questions.map((question) => {
            switch (question.type) {
                case "boolean":
                    return (<div key={question.id} className="checkInQuestion"><FormControlLabel
                        control={<Switch defaultChecked/>} label={question.question}
                        onChange={(event) => {
                            question.selectedValue = event.target.valueOf().toString();
                        }}/></div>);
                case "dropdown": {
                    const options = question.content.split(":");
                    return (<div key={question.id} className="checkInQuestion"><FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{question.question}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={options[0]}
                            label={question.question}
                            onChange={(event) => handleChange(event, question)}
                        >
                            {options.map((option) => {
                                return <MenuItem value={option}>{option}</MenuItem>;
                            })}
                        </Select>
                    </FormControl></div>);
                }
                case "string":
                    return (<div key={question.id} className="checkInQuestion">
                        <h5>{question.question}</h5>
                        <TextField id="exampleTextarea" onChange={(e) => {
                            question.selectedValue = e.target.value;
                        }} multiline rows={4}/>
                    </div>);
                case "slider": {
                    const sliderValues = question.content.split(":");

                    return (<div key={question.id} className="checkInQuestion">
                        <h5>{question.question}</h5>
                        <Slider
                            aria-label="Temperature"
                            valueLabelDisplay="auto"
                            key={`slider-${question.id}-${Number(sliderValues[2])}`}
                            defaultValue={Number(sliderValues[2])}
                            step={1}
                            onChange={(e, val) => {
                                question.selectedValue = val.toString();
                            }}
                            marks
                            min={Number(sliderValues[0])}
                            max={Number(sliderValues[1])}
                            sx={{
                                color: "#CEFF00"
                            }}

                        />
                    </div>);
                }
                default:
                    return (<div></div>);
            }
        }));

        if (questions !== undefined) {
            questions.map((question) => response.push(question));

            const ImageUpload = <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <button
                            className="btn btn-info"
                            style={isDragging ? {color: "red"} : undefined}
                            type="button"
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            Add Image
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-info" onClick={onImageRemoveAll}>Remove all
                            images
                        </button>
                        {imageList.map((image, index) => (
                            <div key={index}>
                                <img className="img-thumbnail" src={image.dataURL} alt="" width="100%"/>
                                <div className="image-item__btn-wrapper flex-column">
                                    <button type="button" className="btn btn-info"
                                            onClick={() => onImageUpdate(index)}>Update
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn-info"
                                            onClick={() => onImageRemove(index)}>Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>;


            const userFormSubmitButton = <div className="row">
                <div className="col-md-12 flex">
                    <Button variant="contained" onClick={handleSubmit}
                            disabled={!canSubmit}>Submit</Button>
                </div>
            </div>;
            response.push(ImageUpload);
            response.push(userFormSubmitButton);
        }
        return response;
    };


    // @ts-ignore
    return <div className="container">
        <Container maxWidth="xl" sx={{p: "0px !important"}}>
            <div className="row">
                <div className="col-md-12 client-info">
                    <div className="card card-header-check-in">
                        <div className="form-img-header card-header ">
                            <img src={require("../assets/images/AMPT-APP-ClientForm-1.png")} className="form-header"
                                 alt="Ampt Check In"/>
                        </div>
                    </div>
                </div>
            </div>

            <Box
                component="form"
                sx={{
                    mt: 3,
                    "& .MuiTextField-root": {my: 1, width: "100%"},
                }}
                noValidate
                autoComplete="off"

            >
                <Container maxWidth="xl">
                    <div>{formOpertingMode()}</div>
                </Container>

            </Box>
        </Container>
    </div>;

    function formOpertingMode() {
        switch (operatingMode) {
            case 1:
                return InitialLoad();
            case 2:
                return UserNotfound();
            case 3:
                return LoadUserForm();
            case 4:
                return CheckInSubmitted();
            case 5:
                return ErrorLoadingCheckForm();
            default:
                return <div></div>;


        }
    }


}


export default CheckInForm;
