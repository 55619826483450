import axios from "axios";


export const authClient = axios.create({
    baseURL: process.env.REACT_APP_AUTH_BASE_URL
});

export const checkInClient = axios.create({
    baseURL: process.env.REACT_APP_CHECK_IN_BASE_URL
})


export function getRequest(url: string, queryParams = {}, client = 'AUTH') {
    if(client === 'AUTH') {
        return authClient.get(url, {
            params: queryParams
        });
    } else {
        return checkInClient.get(url, {
            params: queryParams
        });
    }
}


export function postRequest(url: string, body = {}, queryParams = {}, client = 'AUTH') {
    if(client === 'AUTH') {
        return authClient.post(url, body, {
            params: queryParams
        });
    } else {
        return checkInClient.post(url, body, {
            params: queryParams
        });
    }
}



