import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import CheckInForm from "./components/CheckInForm";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";



const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false
        }
    }
});
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            {/*<App />*/}
                <CheckInForm/>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
